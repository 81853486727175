import "core-js/modules/es.array.push.js";
import imageIcon from '../../assets/icon-image.png';
import imageDisabeldIcon from '../../assets/icon-image-disabled.png';
import { terminalImgDetailSummary } from '@/api/wxwork';
export default {
  data() {
    return {
      imageIcon,
      imageDisabeldIcon,
      imageData: {}
    };
  },
  mounted() {
    document.title = '审核结果';
    this.getData();
  },
  methods: {
    getData() {
      let {
        week,
        terminalCode,
        planCodeList
      } = this.$route.query;
      if (typeof planCodeList === 'string') {
        planCodeList = [planCodeList];
      }
      console.log(planCodeList);
      terminalImgDetailSummary({
        week,
        terminalCode,
        planCodeList: planCodeList
      }).then(res => {
        if (res.data.status) {
          let imageData = res.data.data && res.data.data.length ? res.data.data[0] : {};
          imageData.taskList.sort((a, b) => b.imgCnt - a.imgCnt);
          this.imageData = imageData;
        }
      });
    },
    goDetail(data) {
      if (!data.imgCnt) return;
      this.$router.push({
        path: '/auditImages',
        query: {
          ...this.$route.query,
          taskCode: data.taskCode,
          taskName: data.taskName
        }
      });
    }
  }
};