import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7e17df9e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container auditResult"
};
const _hoisted_2 = {
  class: "top"
};
const _hoisted_3 = {
  class: "store-name"
};
const _hoisted_4 = {
  class: "code"
};
const _hoisted_5 = {
  class: "address"
};
const _hoisted_6 = {
  class: "brand"
};
const _hoisted_7 = {
  class: "image-box"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($data.imageData.terminalName), 1), _createElementVNode("div", _hoisted_4, "终端编码：" + _toDisplayString($data.imageData.terminalCode), 1), _createElementVNode("div", _hoisted_5, "省市：" + _toDisplayString($data.imageData.province) + _toDisplayString($data.imageData.city), 1), _createElementVNode("div", _hoisted_6, _toDisplayString($data.imageData.brand), 1)]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.imageData.taskList, item => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(['image-item', !item.imgCnt && 'disabled']),
      key: item.taskCode,
      onClick: $event => $options.goDetail(item)
    }, [_createElementVNode("img", {
      class: "icon",
      src: item.imgCnt ? $data.imageIcon : $data.imageDisabeldIcon,
      alt: ""
    }, null, 8, _hoisted_9), _createElementVNode("div", _hoisted_10, _toDisplayString(item.taskName), 1)], 10, _hoisted_8);
  }), 128))]), _createElementVNode("div", {
    class: "back-btn",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
  }, "返回")]);
}